

























































    import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
    import { Mutation } from 'vuex-class'
    import { Post } from '../store/post/types'
    import { API_BASE_URL } from '../config';

    @Component({
        filters: {
            timeAgo: (post: Post) : string => {
                let diff = Math.abs(new Date(post.published).getTime() - (new Date()).getTime());
                var minutes = Math.ceil(diff / (1000 * 60));
                if (minutes == 1) {
                    return "zeg maar nu net";
                }
                if (minutes < 60) {
                    return minutes + " minuten geleden";
                }
                var hours = Math.ceil(minutes / 60);
                if (hours < 24) {
                    return "pak 'm beet " + hours + " uur geleden";
                }
                var days = Math.ceil(hours / 24);
                if (days <= 31) {
                    return days + " dagen geleden";
                }
                if (days > 31) {
                    return " lang geleden";
                }
            },

            upvoteFormat: (upvotes: number) : string => {
                if (upvotes < 1000) {
                    return upvotes.toString();
                }
                if (upvotes >= 1000 && upvotes < 1000000) {
                    let thousands = Math.floor(upvotes / 1000);
                    let hundreds = Math.floor((upvotes - (thousands * 1000)) / 100);
                    return thousands.toString() + '.' +  hundreds.toString() + 'k';
                }
                if (upvotes >= 1000000) {
                    let million = Math.floor(upvotes / 1000000);
                    let hundredThousands = Math.floor((upvotes - (million * 1000000)) / 100000);
                    return million.toString() + '.' +  hundredThousands.toString() + 'm';
                }
            }
        }
    })
    export default class Posts extends Vue {
        @Mutation("setNewPost") setNewPost;
	    @Prop() posts: Posts[];
        newPostTitle: string = null;
        order: number = 0;
        apiBaseUrl: string = API_BASE_URL;
        
        getIcon(postType: string) {
            switch (postType) {
                case 'News':
                    return 'quote-right';
                case 'Review':
                    return 'wrench';
                case 'Question':
                    return 'question';
                case 'FreeForm':
                    return 'comment';
                case 'HowTo':
                    return 'lightbulb';
                case 'BlogPost':
                    return 'pencil-alt';
                default:
                    break;
            }
        }

        startNewPost() : void {
            let newPost = new Post();
            newPost.title = this.newPostTitle;
            this.setNewPost(newPost);
            this.$router.push('/post/new');
        }

        @Emit('order-selected')
        selectOrder(order: number) : number {
            this.order = order;
            return this.order;
        }

        orderButtonClass(order: number) : string {
            if (order == this.order) return 'is-primary';
            return 'is-off';
        }

        classPinned(post: Post) : string {
            if (post.pinned) return 'post-row-pinned';
            return '';
        }
    }
