import 'babel-polyfill';

import Vue from 'vue';
import App from './app.vue';
import store from './store'
import './icons'
import { router } from './routes';
import vSelect from 'vue-select';
import Title from './mixins/title';
import VueMatomo from 'vue-matomo';

Vue.use(VueMatomo, {
	host: "https://analytics.tellick.nl",
	siteId: 3,
	trackerFileName: 'matomo',
	router: router,
	enableLinkTracking: true,
	requireConsent: false,
	trackInitialView: true,
	disableCookies: true,
	enableHeartBeatTimer: false,
	heartBeatTimerInterval: 15,
	debug: false,
	userId: undefined,
	cookieDomain: undefined,
	domains: undefined,
	preInitActions: []
  });


new Vue({
	router,
	store,
	render: h => h(App),
	el: '#app'
});

Vue.component('v-select', vSelect)
Vue.mixin(Title);
