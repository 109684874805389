






import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Posts from '../../components/posts';

@Component({ components: { Posts } })
export default class HowTo extends Vue {
    @Action("loadHowtos") loadHowtos;
    @Getter("getHowtos") howtos;

    created() : any {
        this.loadHowtos();
    }
}
