


















import { Component, Vue } from 'vue-property-decorator';
import { ServiceBase } from '../services/service-base';
import * as config from '../config'

@Component
export default class VerifyEmail extends Vue { 
    verified: boolean = null;
    
    async mounted() {
        let emailAdress = this.$route.query.emailAddress;
        let verificationCode = this.$route.query.verificationCode;

        let serviceBase: ServiceBase = new ServiceBase();
        let response = await serviceBase.axios.post(
            config.API_BASE_URL + 'auth/verify-email', 
            {
                emailAddress: emailAdress,
                verificationCode: verificationCode
            });
        if (response.status == 200) {
            this.verified = true;
        } else {
            this.verified = false;
        }
    }
}
