import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './root-state';
import { user } from './user' 
import { post } from './post' 
import { leaderBoard } from './leader-board'
import { subtalk } from './subtalk'

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0'
    },
    modules: {
        user,
        post,
        leaderBoard,
        subtalk
    }
};

export default new Vuex.Store<RootState>(store);