





























import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Invite } from '../store/user/types';

@Component
export default class Invites extends Vue { 
    @Getter('getInvites') invites;
    @Action('loadInvites') loadInvites;
    @Action('sendInvite') sendInvite;
    inviteCodesSending: string[] = [];

    mounted() {
        this.loadInvites();
    }

    sentOrUsed(invite: Invite) : boolean {
        return invite.used > 0 || (invite.receiver !== null);
    }

    async sendThisInvite(invite: Invite) : Promise<void> {
        if (!this.isValidEmail(invite.new_receiver)) return;
        this.inviteCodesSending.push(invite.code);
        invite.receiver = invite.new_receiver;
        await this.sendInvite(invite);
        this.inviteCodesSending.splice(this.inviteCodesSending.indexOf(invite.code), 1);
    }

    sending(invite: Invite) : boolean {
        return this.inviteCodesSending.indexOf(invite.code) != -1;
    }

    isValidEmail(emailAddress: string) : boolean {
        if (emailAddress == undefined || emailAddress == null || emailAddress == '') return true;

        var regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var result = emailAddress !== undefined && regEx.test(emailAddress);

        return result;
    }
}
