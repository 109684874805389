






import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Posts from '../../components/posts';

@Component({ components: { Posts } })
export default class News extends Vue {
    @Action("loadNews") loadNews;
    @Getter("getNews") news;

    created() : any {
        this.loadNews();
    }
}
