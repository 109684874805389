





















































import Spinner from '../components/spinner';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from "vuex-class";
import { Credentials } from "../store/user/types";
import * as config from '../config';

@Component({ 
    components: {
        Spinner
    }
})
export default class SignIn extends Vue { 
    @Action("signin") signin;
    loggingIn: boolean = false;

    mounted() {
    }

    async performSignin(e: Event) : Promise<void> {
        e.preventDefault();
        this.loggingIn = true;
        let loggedIn = await this.signin({
            username: (this.$refs.user as HTMLInputElement).value,
            password: (this.$refs.pass as HTMLInputElement).value
        });
        if (loggedIn) {
            this.$router.push('/');
        } else {
            setTimeout(() => { 
                this.loggingIn = false;
            }, 500);
        }
    }
}
