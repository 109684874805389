import Vue from 'vue';
import VueRouter, { NavigationGuard, NavigationGuardNext, Route, RouteRecord } from 'vue-router'
import store from './store'

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        component: () => import('./route/home.vue'),
        name: 'home'
    },
    {
        path: '/s/:subtalk',
        component: () => import('./route/subtalk/index.vue'),
        name: 'subtalk'
    },
    {
        path: '/u/:username',
        component: () => import('./route/user/index.vue'),
        name: 'user'
    },
    {
        path: '/profile',
        component: () => import('./route/profile/index.vue'),
        name: 'profile'
    },
    {
        path: '/post/new',
        component: () => import('./route/post/new-post.vue'),
        name: 'new-post'
    },
    {
        path: '/post/:postId/edit',
        component: () => import('./route/post/edit-post.vue'),
        name: 'edit-post'
    },
    {
        path: '/post/:postId/:postUrl',
        component: () => import('./route/post/post.vue'),
        name: 'post'
    },
    {
        path: '/news',
        component: () => import('./route/news/index.vue'),
        name: 'news'
    },
    {
        path: '/reviews',
        component: () => import('./route/reviews/index.vue'),
        name: 'reviews'
    },
    {
        path: '/questions',
        component: () => import('./route/questions/index.vue'),
        name: 'questions'
    },
    {
        path: '/discussion',
        component: () => import('./route/discussion/index.vue'),
        name: 'discussion'
    },
    {
        path: '/howtos',
        component: () => import('./route/howto/index.vue'),
        name: 'howtos'
    },
    {
        path: '/blogs',
        component: () => import('./route/blog/index.vue'),
        name: 'blogs'
    },
    {
        path: '/pricetech',
        component: () => import('./route/pricetech/index.vue'),
        name: 'pricetech'
    },
    {
        path: '/leaderboard',
        component: () => import('./route/leaderboard/index.vue'),
        name: 'leaderboard'
    },
    {
        path: '/signup',
        component: () => import('./route/signup.vue'),
        name: 'signup',
        meta: {
            disableChat: true,
            requireAnonymous: true,
        }
    },
    {
        path: '/signin',
        component: () => import('./route/signin.vue'),
        name: 'signin',
        meta: {
            disableChat: true,
            requireAnonymous: true,
        }
    },
    {
        path: '/invites',
        component: () => import('./route/invites.vue'),
        name: 'invites'
    },
    {
        path: '/verify-email',
        component: () => import('./route/verify-email.vue'),
        name: 'verify-email',
        meta: {
            disableChat: true,
            requireAnonymous: true,
        }
    },
    {
        path: '/password/request',
        component: () => import('./route/password/request.vue'),
        name: 'password-request',
        meta: {
            disableChat: true,
            requireAnonymous: true,
        }
    },
    {
        // All other requests, go to not found
        path: '/404',
        component: () => import('./route/not-found.vue'),
        name: 'notFound'
    },
    {
        // All other requests, go to not found
        path: '*',
        component: () => import('./route/not-found.vue'),
        name: 'notFound'
    }
]

export const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    if (to.matched.some((record: RouteRecord) => record.meta.requireAnonymous)) {
        if (localStorage.getItem('tbnt:loggedIn')) {
            next({ name: 'home' })
            return
        }
        next()
    } else {
        next()
    }
})

export default router
