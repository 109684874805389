export class Reply {
    id: string;
    content: string;
    published: Date;
    postId: string;
    username: string;
    upvotes: number;
    upvotedByYou: boolean;
}

export class News {
    source: string;
}

export class PostBase {
    id: string;
    title: string;
    url: string;
    content: string;
}

export class Post extends PostBase {
    subtalk: string;
    subtalkId: string;
    published: Date;
    type: string;
    pinned: boolean;
    featureImageId: string;
    upvotes: number;
    upvotedByYou: boolean;
    replies: Reply[];
    username: string;
    news: News;
}

export interface PostState {
    featured: Post[];
    postsByCurrentSubtalk: Post[];
    news: Post[];
    reviews: Post[];
    questions: Post[];
    discussions: Post[];
    howtos: Post[];
    blogs: Post[];
    currentPost: Post;
    currentReplies: Reply[];
    newReply: Reply;
    newPost: Post;
}