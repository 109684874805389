import { ActionContext, Module, GetterTree, ActionTree, MutationTree, Action } from 'vuex';
import { Post, PostState, Reply } from './types';
import { RootState } from '../root-state';
import { ServiceBase } from '../../services/service-base'
import { AxiosResponse } from 'axios';

export const getters: GetterTree<PostState, RootState> = {
    getFeatured(state: PostState) : Post[] {
        return state.featured;
    },

    getNews(state: PostState) : Post[] {
        return state.news;
    },

    getReviews(state: PostState) : Post[] {
        return state.reviews;
    },

    getQuestions(state: PostState) : Post[] {
        return state.questions;
    },

    getDiscussions(state: PostState) : Post[] {
        return state.discussions;
    },

    getHowtos(state: PostState) : Post[] {
        return state.howtos;
    },

    getBlogs(state: PostState) : Post[] {
        return state.blogs;
    },

    getPostsByCurrentSubtalk(state: PostState) : Post[] {
        return state.postsByCurrentSubtalk;
    },

    getCurrentPost(state: PostState) : Post {
        return state.currentPost;
    },

    getCurrentReplies(state: PostState) : Reply[] {
        return state.currentReplies;
    },

    getNewPost(state: PostState) : Post {
        return state.newPost;
    },

    getNewReply(state: PostState) : Reply {
        return state.newReply;
    }
};

export const actions: ActionTree<PostState, RootState> = {
    clearCurrent(context: ActionContext<PostState, RootState>) {
        context.commit('setCurrentPost', null);
        context.commit('setCurrentReplies', null);
    },

    loadFeatured(context: ActionContext<PostState, RootState>, order: number): any {
        let serviceBase = new ServiceBase();
        serviceBase.axios.get('/post/featured/' + order).then(i => {
            context.commit('setFeatured', i.data);
        });
        // @TODO error handling
    },

    loadPostsBySubtalk(context: ActionContext<PostState, RootState>, subtalk: string) : any {
        let serviceBase = new ServiceBase();
        serviceBase.axios.get('/subtalk/' + subtalk + '/posts').then(i => {
            context.commit('setPostsByCurrentSubtalk', i.data);
        });
    },

    loadNews(context: ActionContext<PostState, RootState>): any {
        let serviceBase = new ServiceBase(); // I KNOW!
        serviceBase.axios.get('/post/all/news').then(i => {
            context.commit('setNews', i.data);
        });
    },

    loadReviews(context: ActionContext<PostState, RootState>): any {
        let serviceBase = new ServiceBase(); // I KNOW!
        serviceBase.axios.get('/post/all/review').then(i => {
            context.commit('setReviews', i.data);
        });
    },

    loadQuestions(context: ActionContext<PostState, RootState>): any {
        let serviceBase = new ServiceBase(); // I KNOW!
        serviceBase.axios.get('/post/all/question').then(i => {
            context.commit('setQuestions', i.data);
        });
    },

    loadDiscussions(context: ActionContext<PostState, RootState>): any {
        let serviceBase = new ServiceBase(); // I KNOW!
        serviceBase.axios.get('/post/all/freeform').then(i => {
            context.commit('setDiscussions', i.data);
        });
    },

    loadHowtos(context: ActionContext<PostState, RootState>): any {
        let serviceBase = new ServiceBase(); // I KNOW!
        serviceBase.axios.get('/post/all/howto').then(i => {
            context.commit('setHowtos', i.data);
        });
    },

    loadBlogs(context: ActionContext<PostState, RootState>): any {
        let serviceBase = new ServiceBase(); // I KNOW!
        serviceBase.axios.get('/post/all/blogpost').then(i => {
            context.commit('setBlogs', i.data);
        });
    },

    loadPost(context: ActionContext<PostState, RootState>, postId: string) : any {
        context.commit('setCurrentPost', null);
        let serviceBase = new ServiceBase();
        serviceBase.axios.get('/post/' + postId).then(i => {
            context.commit('setCurrentPost', i.data);
            context.dispatch('loadReplies', i.data.id);
        });
        // @TODO error handling
    },

    loadReplies(context: ActionContext<PostState, RootState>, postId: string) : any {
        let serviceBase = new ServiceBase();
        serviceBase.axios.get('/post/' + postId + '/replies').then(i => {
            context.commit('setCurrentReplies', i.data);
        });
        // @TODO error handling
    },

    async publishNewPost(context: ActionContext<PostState, RootState>) : Promise<any> {
        let serviceBase = new ServiceBase();
        let response = await serviceBase.axios.post('/post', context.state.newPost);
        return response.data;
        // @TODO error handling
    },

    async editPost(context: ActionContext<PostState, RootState>, post: Post) : Promise<any> {
        let serviceBase = new ServiceBase();
        let response = await serviceBase.axios.put('/post/' + post.id, post);
        return response.data;
    }

    async publishReply(context: ActionContext<PostState, RootState>) : Promise<Post> {
        let serviceBase = new ServiceBase();
        return serviceBase.axios.put('/post/' + context.state.currentPost.id + '/replies', context.state.newReply);
        // @TODO error handling
    },

    upvote(context: ActionContext<PostState, RootState>, postId: string) {
        let serviceBase = new ServiceBase();
        return serviceBase.axios.put('/post/upvote/' + postId, null).then(i => { /* noop */ });
        // @TODO error handling   
    },

    upvoteReply(context: ActionContext<PostState, RootState>, replyId: string) {
        let serviceBase = new ServiceBase();
        return serviceBase.axios.put('/reply/' + replyId + '/upvote', null).then(i => { /* noop */ });
    },

    async uploadImage(context: ActionContext<PostState, RootState>, image: Blob) : Promise<string> {
        return new Promise<string>((resolve, reject) => { 
            var reader = new FileReader();
            reader.onload = function() {
                var dataUrl = reader.result as string;
                var base64 = dataUrl.split(',')[1];
                let serviceBase = new ServiceBase();
                serviceBase.axios.post('/file', {
                    base64Data: base64,
                    mimeType: image.type
                }).then(i => { 
                    resolve(i.data.id);
                }).catch(i => {
                    reject();
                });
            };
            reader.readAsDataURL(image);
        });
    }
};

export const mutations: MutationTree<PostState> = {
    setFeatured(state: PostState, payload: Post[]) {
        state.featured = payload;
    },

    setPostsByCurrentSubtalk(state: PostState, payload: Post[]) {
        state.postsByCurrentSubtalk = payload;
    },

    setNews(state: PostState, payload: Post[]) {
        state.news = payload;
    },

    setReviews(state: PostState, payload: Post[]) {
        state.reviews = payload;
    },

    setQuestions(state: PostState, payload: Post[]) {
        state.questions = payload;
    },

    setDiscussions(state: PostState, payload: Post[]) {
        state.discussions = payload;
    },

    setHowtos(state: PostState, payload: Post[]) {
        state.howtos = payload;
    },

    setBlogs(state: PostState, payload: Post[]) {
        state.blogs = payload;
    },

    setCurrentPost(state: PostState, payload: Post) {
        if (payload !== null) {
            payload.replies = [];
        }
        state.currentPost = payload;
    },

    setCurrentReplies(state: PostState, payload: Reply[])  {
        state.currentReplies = payload;
    },
    
    setNewPost(state: PostState, paylad: Post) {
        state.newPost = paylad;
    },

    setNewReply(state: PostState, payload: Reply) {
        state.newReply = payload;
    }
};

export const state: PostState = {
    featured: null,
    postsByCurrentSubtalk: null,
    news: null,
    reviews: null,
    questions: null,
    discussions: null,
    howtos: null,
    blogs: null,
    currentPost: null,
    currentReplies: null,
    newPost: null,
    newReply: null
};

const namespaced: boolean = false;
export const post: Module<PostState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};