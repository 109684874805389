






import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Posts from '../../components/posts';

@Component({ components: { Posts } })
export default class Reviews extends Vue {
    @Action("loadReviews") loadReviews;
    @Getter("getReviews") reviews;

    created() : any {
        this.loadReviews();
    }
}
