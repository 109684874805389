










































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Credentials } from "../store/user/types";
import * as config from '../config';

@Component
export default class User extends Vue {
    @Action("signin") signin;
    @Action("signout") signout;
    @Action("loadLoggedIn") loadLoggedIn;
    @Getter("getLoggedIn") loggedIn;
    @Getter("getCurrentUser") currentUser;
    @Action('loadCurrentUser') loadCurrentUser;

    modalActive: Boolean = false;
    credentials: Credentials = new Credentials();
    baseUrl: string;

    created() : void {
        this.baseUrl = config.API_BASE_URL;
        if (this.currentUser == null) {
            this.loadCurrentUser();
        }
        this.loadLoggedIn();
    }

    performSignin() : void {
        this.signin(this.credentials);
        this.toggleModal(false);
    }

    toggleModal(active: Boolean) : void {
        this.modalActive = active;
    }

    getModalActiveClass() : string {
        return this.modalActive == true ? "is-active" : "";
    }

    performSignout() : void {
        this.signout();
        this.$router.push('/');
    }
}
