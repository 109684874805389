







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import * as signalR from "@aspnet/signalr";
import { IHttpConnectionOptions } from '@aspnet/signalr';
import moment from 'moment';
import * as config from '../config';

export class ChatMessage {
    id: string;
    username: string;
    message: string;
    dateTime: Date;
    messageType: string;
} 

@Component({
    filters: {
        chatDate: (input: Date) : string => moment(input).format('D-M-yyyy HH:mm')
    }
})
export default class Chat extends Vue {
    @Getter("getFeatured") featuredPosts;
    @Getter("getCurrentRoom") currentRoom;
    @Action('changeCurrentRoom') changeCurrentRoom;
    @Action('loadLoggedIn') loadLoggedIn;
    @Getter('getLoggedIn') loggedIn;

    previousRoom: string = null;
    connection: signalR.HubConnection = null;
    messages: ChatMessage[] = [];
    message: string = null;
    saveMessage: string = null;
    history: string[] = [];
    historyIndex: number;
    baseUrl: string;

    created() : void {
        if (this.loggedIn === null) {
            this.loadLoggedIn();
        } else {
            this.connect();
        }
    }

    mounted() : void {
    }


    connect() : void {
        if (this.loggedIn && this.connection == null) {
            this.baseUrl = config.API_BASE_URL;

            const options: IHttpConnectionOptions = {
                accessTokenFactory: () => {
                    return localStorage.getItem('tbnt:id');
                }
            };

            // @todo: use base url's
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(config.API_BASE_URL + 'chat', options)
                .configureLogging(signalR.LogLevel.Error)
                .build();

            let that = this;
            this.$nextTick(() => {
                that.connection.start().then(() => {
                    //that.connection.invoke('Join', this.currentRoom).then(() => { /* noop */ });
                    that.connection.on("AMessage", function(message: ChatMessage) {
                        message.messageType = 'user';
                        that.messages.push(message);
                    });

                    that.changeCurrentRoom('/');
                });
                
                
                //that.previousRoom = '/';
            });
        }
    }

    sendMessage(message: string) : void {
        if (message === null || message.length == 0) return;

        if (message.startsWith('/')) {
            this.runCommand(message);
            this.message = "";
        } else {
            let that = this;
            console.log(this.connection.state);
            this.connection
                .invoke("SendMessageToRoom", message, that.currentRoom)
                .then(function() {
                    that.message = "";
                })
                .catch(function(err) {
                    return console.error(err);
                });
        }

        this.history.push(this.message);
        this.historyIndex = this.history.length;
    }

    @Watch('messages')
    onMessagesChanged() : void {
        this.$nextTick(function() {
            let element = document.getElementsByClassName("chat-messages");
            element[0].scrollTop = element[0].scrollHeight;
        });
    }

    runCommand(command: string) : void {
        if (command.startsWith("/open ")) {
            let tokens = command.split(' ');
            if (tokens.length != 2) {
                this.commandError('\'/open\' requires at least and only one argument!')
            }
            if (tokens[1] == 'first') {
                this.$router.push('/post/' + this.featuredPosts[0].url);
                this.commandSucces(`Opened '${this.featuredPosts[0].title}'`)
            }
            return;
        }
        if (command === "/home") {
            this.commandSucces('going home!')
            this.$router.push('/');
            return;
        }
        this.commandError('command not found')
    }

    commandSucces(successMessage: string) {
        let chatMessage = new ChatMessage();
        chatMessage.message = `💚 ${successMessage}`;
        chatMessage.messageType = 'command';
        this.messages.push(chatMessage);
    }

    commandError(errorMessage: string) {
        let chatMessage = new ChatMessage();
        chatMessage.message = '😔 ' + errorMessage;
        chatMessage.messageType = 'command';
        this.messages.push(chatMessage);
    }

    historyBack() {
        if (this.historyIndex == this.history.length) {
            this.saveMessage = this.message;
        }

        if (this.historyIndex > 0) {
            this.historyIndex--;

            if (this.historyIndex < this.history.length) {
                this.message = this.history[this.historyIndex];
            }
        }
    }

    historyForward() {
        if (this.historyIndex <= this.history.length) {
            this.historyIndex++;
        }
        if (this.historyIndex < this.history.length) {
            this.message = this.history[this.historyIndex];
        } else {
            this.message = this.saveMessage;
        }
    }

    @Watch('currentRoom')
    onCurrentRoomChanges() : void {
        if (this.connection != null && this.connection.state == signalR.HubConnectionState.Connected) {
            if (this.previousRoom != null) {
                this.connection.invoke('Leave', this.previousRoom).then(() => { /* noop */});
            }
            this.connection.invoke('Join', this.currentRoom).then(() => { /* noop */});
            this.previousRoom = this.currentRoom;
        }
    }

    @Watch('loggedIn')
    onLoggedInChanges() : void {
        this.$nextTick(function() {
            this.connect();
        });
    }
}
