






import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Posts from '../../components/posts';

@Component({ components: { Posts } })
export default class Blog extends Vue {
    @Action("loadBlogs") loadBlogs;
    @Getter("getBlogs") blogs;

    created() : any {
        this.loadBlogs();
    }
}
