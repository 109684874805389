











































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import * as config from '../../config';
import ProfilePictureEditDialog from '../../components/profile-picture-edit-dialog';

@Component({
	components: { ProfilePictureEditDialog },
})
export default class Profile extends Vue {
    @Getter('getUserSettings') userSettings;
    @Action('loadUserSettings') loadUserSettings;
    @Action('saveUserSettings') saveUserSettings;
    @Action('loadCurrentUser') loadCurrentUser;
    @Getter('getCurrentUser') currentUser;
    @Action('loadCurrentProfile') loadCurrentProfile;
    @Getter('getCurrentProfile') currentProfile;
    @Action('saveProfile') saveProfile;

    saveButtonActive: boolean = false;

    baseUrl: string;
    profilePicture = {
		editor: {
			show: false,
			url: null,
			type: null,
		},
		removeDialog: {
			show: false,
		},
	};

    async created(): Promise<void> {
        this.baseUrl = config.API_BASE_URL;
        await this.loadCurrentUser();
        await this.loadCurrentProfile();

        if (this.userSettings == null) {
            this.loadUserSettings();
        }
    }

    changeProfileImage() : void {
        const input = this.$refs.profilePicture as HTMLInputElement;
        input.value = '';
		input.click();
    }

    async saveCurrentProfile() : Promise<void> {
        await this.saveProfile(this.currentProfile);
        this.saveButtonActive = false;
    }

    onCloseEditor() : void {
        this.profilePicture.editor.show = false;
        this.loadCurrentUser();
    }

    onProfilePictureChange(): void {
		const input = this.$refs.profilePicture as HTMLInputElement;
		if (input.files.length < 1) return;

		const file = input.files.item(0);
		const url = URL.createObjectURL(file);
		this.profilePicture.editor = {
			show: true,
			type: file.type,
			url: url,
		};
	}

    @Watch('userSettings.richardMode')
    @Watch('userSettings.richardModePlus')
    onRicharModeChanged() {
        this.saveUserSettings(this.userSettings);
    }

    @Watch('currentProfile.about')
    onProfileAboutChanged() {
        this.saveButtonActive = true;
    }
}
