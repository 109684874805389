







































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from "vuex-class";
import User from './user'

@Component({
    components: { User }
})
export default class Header extends Vue {
    @Getter("getCurrentUser") currentUser;
    showNavbarMenu: boolean = false;

    toggleNavbarMenu() : void {
        this.showNavbarMenu = !this.showNavbarMenu;
    }

    getNavbarMenuActiveClass() : string {
        if (this.showNavbarMenu) {
            return 'is-active';
        } else {
            return '';
        }
    }
}
