
























import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component
export default class Leaderboard extends Vue {
    @Action('loadLeaders') loadLeaders;
    @Getter('getLeaders') leaders;

    created() {
        this.loadLeaders();
    }
}
