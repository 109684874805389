
















import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Posts from '../../components/posts';

@Component({ components: { Posts } })
export default class Subtalk extends Vue {
    @Getter('getPostsByCurrentSubtalk') postsByCurrentSubtalk;
    @Action('loadPostsBySubtalk') loadPostsBySubtalk;

    currentSubtalk: string; // todo(might be smart to elevate to store)

    created() {
        this.currentSubtalk = this.$route.params.subtalk;
        this.loadPostsBySubtalk(this.currentSubtalk);
    }
}
