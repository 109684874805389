







import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class'
import Posts from '../components/posts';
import { Post } from '../store/post/types'

@Component({ components: { Posts } })
export default class Home extends Vue {
    
    @Action("loadFeatured") loadFeatured;
    @Getter("getFeatured") featured;

    created() {
        this.loadFeatured(0);
        document.title = 'Technobabble Nerd Talk - de plek voor technologie!';
    }

    orderChanged(order: number) {
        this.loadFeatured(order);
    }

    title() : string {
        return "asddas";
    }
}
