





import { Component, Vue } from 'vue-property-decorator';

@Component()
export default class PasswordRequest extends Vue {
    created() {

    }
}
