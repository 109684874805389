

























import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as config from '../../config';

@Component
export default class User extends Vue {
    @Action('loadProfile') loadProfile;
    @Getter('getProfile') profile;

    baseUrl: string;

    created() {
        this.baseUrl = config.API_BASE_URL;
        this.loadProfile(this.$route.params.username);
    }
}
