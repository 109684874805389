






























import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, Action, State } from 'vuex-class'
import { Editor } from '@toast-ui/vue-editor';
import '../../style/editor.scss';
import { News, Post } from '../../store/post/types';
import { Subtalk } from '../../store/subtalk/types';
import * as tbntConfig from '../../config';

@Component({
    components: { Editor }
})
export default class NewPost extends Vue {
    @Getter('getNewPost') newPost: Post;
    @Getter('getAllSubtalks') allSubtalks: Subtalk[];
    @Mutation('setNewPost') setNewPost;
    @Action('publishNewPost') publishNewPost;
    @Action('loadAllSubtalks') loadAllSubtalks;
    @Action('uploadImage') uploadImage;

    selectedSubtalk: string = null;
    selectedType: string = null;

    editor: Editor = null;

    async created() : Promise<void> {
        if (this.newPost == null) {
            let post = new Post();
            this.setNewPost(post);
        }
        await this.loadAllSubtalks();
    }

    mounted() {
        this.editor = (this.$refs.toastUiEditor as Editor);
        this.editor.invoke('addHook', 'addImageBlobHook', this.addImageBlobHook);
    }

    async addImageBlobHook(blob: Blob, callback: any) {
        if (blob.size > 1_048_576) {
            alert('Het bestand mag niet groter zijn dan 1MB');
            return;
        }
        let result: string = await this.uploadImage(blob);
        callback(tbntConfig.API_BASE_URL + 'file/' + result, '');
    }

    async publish() : Promise<void> {
        let markdown = this.editor.invoke('getMarkdown');
        if (markdown.length == 0) return;
        this.newPost.content = this.replaceAll(markdown, '<br>', '\n');
        this.newPost.url = this.replaceAll(this.newPost.title.toLowerCase(), ' ', '-');
        let data = await this.publishNewPost();
        this.$router.push('/post/' + data.id + '/' + data.url);
    }

    replaceAll(str: string, find: string, replace: string) : string {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    select(type: string) : void {
        this.selectedType = type;
        this.newPost.type = this.selectedType;
    }

    selected(type: string) : string {
        if (type == this.selectedType) {
            return 'selected';
        } else {
            return '';
        }
    }

    @Watch('selectedType')
    onSelectedTypeChanges() {
        if (this.newPost === null) return;
        this.newPost.news = null;
        if (this.newPost.type == "News") {
            this.newPost.news = new News();
        }
    }

    @Watch('selectedSubtalk') 
    onSelectedSubtalkChanges() {
        if (this.newPost != null) {
            this.newPost.subtalkId = this.selectedSubtalk;
        }
    }
}
