






import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Posts from '../../components/posts';

@Component({ components: { Posts } })
export default class Discussion extends Vue {
    @Action("loadDiscussions") loadDiscussions;
    @Getter("getDiscussions") discussions;

    created() : any {
        this.loadDiscussions();
    }
}
