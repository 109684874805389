

































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Cropper } from 'vue-advanced-cropper';
import { Action } from 'vuex-class';

@Component({
	components: { Cropper },
})	
export default class ProfilePictureEditDialog extends Vue {
	@Action('saveProfileImage') saveProfileImage;

	@Prop({
		type: Boolean,
		default: false,
	})
	private visible;

	@Prop({
		type: String,
		default: '',
	})
	private src;

	@Prop({
		type: String,
		default: 'image/png',
	})
	private type;

	async onSave(): Promise<void> {
		const cropper = this.$refs.cropper as Cropper;

		const { canvas } = cropper.getResult();
		const blob = (await new Promise(resolve => canvas.toBlob(resolve, this.type))) as Blob;
		this.saveProfileImage(blob);
		
		this.$emit('close');
	}

	onClose(): void {
		this.$emit('close');
	}

	onRequestChange(): void {
		this.$emit('request-change');
	}
}
