import axios, { AxiosInstance, AxiosResponse } from 'axios';
import * as tbntConfig from '../config';

export class ServiceBase {
    public axios: AxiosInstance = null;
    constructor() {
        this.axios = axios.create();

        this.axios.interceptors.request.use(
            config => {
                config.headers = {Authorization: `Bearer ${localStorage.getItem('tbnt:id')}` };
                config.baseURL = tbntConfig.API_BASE_URL;
                return config;
            });

        this.axios.interceptors.response.use(
            response => {
                return response;
            }, 
            async error => {
                const originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                  originalRequest._retry = true;
                  await this.refreshAccessToken();
                  return this.axios(originalRequest);
                } else {
                    return error;
                }
            });
    }

    async refreshAccessToken() : Promise<AxiosResponse<any>> {
        return axios.get(tbntConfig.API_BASE_URL + 'auth/refresh_token', { withCredentials: true });
    }
}
