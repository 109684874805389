import { ActionContext, Module, GetterTree, ActionTree, MutationTree, Action } from 'vuex';
import { Credentials, Invite, Profile, User, UserRegistration, UserSettings, UserState } from './types';
import { RootState } from '../root-state';
import { ServiceBase } from '../../services/service-base';
import { AxiosResponse } from 'axios';

export const getters: GetterTree<UserState, RootState> = {
    getLoggedIn(state: UserState) {
        return state.loggedIn;
    },

    getTbntId(state: UserState) {
        return state.tbntId;
    },

    getUserSettings(state: UserState) {
        return state.userSettings;
    },

    getCurrentRoom(state: UserState) {
        return state.currentRoom;
    },

    getUserRegistration(state: UserState) {
        return state.userRegistration;
    },

    getCurrentUser(state: UserState) {
        return state.currentUser;
    },

    getCurrentProfile(state: UserState) {
        return state.currentProfile;
    },

    getProfile(state: UserState) {
        return state.profile;
    },

    getInvites(state: UserState) {
        return state.invites
    }
};

export const actions: ActionTree<UserState, RootState> = {
    startNewUserRegistration(context: ActionContext<UserState, RootState>, inviteCode: string) : void {
        let userRegistration = new UserRegistration();
        userRegistration.inviteCode = inviteCode;
        context.commit('setUserRegistration', userRegistration);
    },

    async registerUser(context: ActionContext<UserState, RootState>, userRegistration: UserRegistration) : Promise<boolean> {
        let serviceBase = new ServiceBase();
        let response = await serviceBase.axios.post('/auth/register', userRegistration);
        return response.status == 200;
    },

    async signin(context: ActionContext<UserState, RootState>, credentials: Credentials) : Promise<boolean> {
        let serviceBase = new ServiceBase();
        let result = await serviceBase.axios.post('/auth/signin', credentials);        
        if (result.status == 200) {
            localStorage.setItem('tbnt:id', result.data.token);
            localStorage.setItem('tbnt:loggedIn', '1');
            context.dispatch('loadTbntId');
            context.dispatch('loadLoggedIn');
            context.dispatch('loadCurrentUser');
            return true;
        } else {
            localStorage.removeItem('tbnt:loggedIn');
            return false;
        }
    },

    signout(context:ActionContext<UserState, RootState>) : void {
        localStorage.removeItem('tbnt:id');
        localStorage.removeItem('tbnt:loggedIn');
        context.commit('setCurrentUser', null);
        context.commit('setLoggedIn', false);
    },

    async loadCurrentUser(context: ActionContext<UserState, RootState>) {
        let serviceBase = new ServiceBase();
        let j = await serviceBase.axios.get('/auth/user-info');
        if (j.status == 200) {
            let user = new User();
            user.username = j.data.username;
            user.profileImageUrl = j.data.profileImageUrl;
            context.commit('setCurrentUser', user);
        }
    },

    async loadCurrentProfile(context: ActionContext<UserState, RootState>) : Promise<void> {
        let serviceBase = new ServiceBase();
        serviceBase.axios.get('/profile').then(i => {
            if (i.status == 200) {
                context.commit('setCurrentProfile', i.data);
            }
        });
    },

    async loadProfile(context: ActionContext<UserState, RootState>, username: string) : Promise<void> {
        let serviceBase = new ServiceBase();
        serviceBase.axios.get('/profile/' + username).then(i => {
            if (i.status == 200) {
                context.commit('setProfile', i.data);
            }
        });
    },

    loadTbntId(context: ActionContext<UserState, RootState>) : void {
        let tbntId = localStorage.getItem('tbnt:id');
        if (tbntId !== null) {
            context.commit('setTbntId', tbntId);
        }
    },

    loadLoggedIn(context: ActionContext<UserState, RootState>) : void {
        let loggedIn = localStorage.getItem('tbnt:loggedIn');
        if (loggedIn !== null) {
            context.commit('setLoggedIn', true);
        } else {
            context.commit('setLoggedIn', false);
        }
    },

    loadUserSettings(context: ActionContext<UserState, RootState>) : void {
        let userSettings = JSON.parse(localStorage.getItem('tbnt:settings')) as UserSettings;
        if (userSettings == null) {
            userSettings = new UserSettings();
            userSettings.richardMode = false;
            userSettings.richardModePlus = false;
            localStorage.setItem('tbnt:settings', JSON.stringify(userSettings));
        }
        context.commit('setUserSettings', userSettings);
    },

    saveUserSettings(context: ActionContext<UserState, RootState>, userSettings: UserSettings) : void {
        localStorage.setItem('tbnt:settings', JSON.stringify(userSettings));
        context.commit('setUserSettings', userSettings);
    },

    changeCurrentRoom(context: ActionContext<UserState, RootState>, currentRoom: string) : void {
        context.commit('setCurrentRoom', currentRoom);
    },

    saveProfileImage(context: ActionContext<UserState, RootState>, profileImage: Blob) : void {
        const reader = new FileReader();
        reader.onload = function() {
            const dataUrl = reader.result.toString();
            const base64 = dataUrl.split(',')[1];
            let serviceBase = new ServiceBase();
            serviceBase.axios.put('/profile/profile-image', {
                base64Data: base64
            }).then(i => { /* noop */});
        };
        reader.readAsDataURL(profileImage);
    },

    async saveProfile(context: ActionContext<UserState, RootState>, profile: Profile) : Promise<void> {
        const serviceBase = new ServiceBase();
        const response = await serviceBase.axios.put('/profile', profile);
        if (response.status == 200) {
            context.commit('setCurrentProfile', profile);
        }
    },

    async loadInvites(context: ActionContext<UserState, RootState>) : Promise<void> {
        let serviceBase = new ServiceBase();
        const response = await serviceBase.axios.get('/auth/invite-codes');
        
        if (response.status == 200) {
            context.commit('setInvites', response.data);
        }
    },

    async sendInvite(context: ActionContext<UserState, RootState>, invite: Invite) : Promise<void> {
        let serviceBase = new ServiceBase();
        const response = await serviceBase.axios.post('/auth/invite', invite);
    }
};

export const mutations: MutationTree<UserState> = {
    setLoggedIn(state: UserState, loggedIn: Boolean) {
        state.loggedIn = loggedIn;
    },

    setTbntId(state: UserState, tbntId: string) {
        state.tbntId = tbntId;
    },

    setUserSettings(state: UserState, userSettings: UserSettings) {
        state.userSettings = userSettings;
    },

    setCurrentRoom(state: UserState, currentRoom: string) {
        state.currentRoom = currentRoom;
    },

    setUserRegistration(state: UserState, userRegistration: UserRegistration) {
        state.userRegistration = userRegistration;
    },

    setCurrentUser(state: UserState, user: User) {
        state.currentUser = user;
    },

    setCurrentProfile(state: UserState, profile: Profile) {
        state.currentProfile = profile;
    },

    setProfile(state: UserState, profile: Profile) {
        state.profile = profile;
    },

    setInvites(state: UserState, invites: Invite[]) {
        state.invites = invites
    }
};

export const state: UserState = {
    currentUser: null,
    currentProfile: null,
    profile: null,
    userSettings: null,
    loggedIn: false,
    tbntId: null,
    currentRoom: null,
    userRegistration: null,
    invites: []
};

const namespaced: boolean = false;
export const user: Module<UserState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
