






import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Posts from '../../components/posts';

@Component({ components: { Posts } })
export default class Questions extends Vue {
    @Action("loadQuestions") loadQuestions;
    @Getter("getQuestions") questions;

    created() : any {
        this.loadQuestions();
    }
}
