export class User {
    username: string;
    profileImageUrl: string
}

export class Profile {
    username: string;
    emailAddress: string;
    about: string;
}

export class Credentials {
    username: string;
    password: string;
}

export class UserSettings {
    richardMode: boolean;
    richardModePlus: boolean;
}

export class UserRegistration {
    inviteCode: string;
    emailAddress: string;
    username: string;
    password: string;
}

export class Invite {
    code: string;
    used: number;
    receiver: string;
    new_receiver: string;
}

export interface UserState {
    currentUser: User,
    currentProfile: Profile,
    profile: Profile,
    userSettings: UserSettings,
    loggedIn: Boolean,
    tbntId: string,
    currentRoom: string,
    userRegistration: UserRegistration,
    invites: Invite[]
}