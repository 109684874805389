import { ActionContext, Module, GetterTree, ActionTree, MutationTree, Action } from 'vuex';
import { Subtalk, SubtalkState } from './types';
import { RootState } from '../root-state';
import { ServiceBase } from '../../services/service-base';
import { AxiosResponse } from 'axios';

export const getters: GetterTree<SubtalkState, RootState> = {
    getAllSubtalks(state: SubtalkState) {
        return state.subtalks;
    }
};

export const actions: ActionTree<SubtalkState, RootState> = {
    async loadAllSubtalks(context: ActionContext<SubtalkState, RootState>) : Promise<void> {
        let serviceBase = new ServiceBase();
        await serviceBase.axios.get('/subtalk/all').then(i => {
            context.commit('setAllSubtalks', i.data);
        });
    },
};

export const mutations: MutationTree<SubtalkState> = {
    setAllSubtalks(state: SubtalkState, payload: Subtalk[]) {
        state.subtalks = payload;
    }
};

export const state: SubtalkState = {
    subtalks: null
};

const namespaced: boolean = false;
export const subtalk: Module<SubtalkState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};