




















import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, Action, State } from 'vuex-class'
import { Editor } from '@toast-ui/vue-editor';
import '../../style/editor.scss';
import { Post, PostBase } from '../../store/post/types';
import { User } from '../../store/user/types';
import * as tbntConfig from '../../config';

// @todo: add to library
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

@Component({
    components: { Editor }
})
export default class EditPost extends Vue {
    @Action('uploadImage') uploadImage;
    @Action('clearCurrent') clearCurrent;
    @Action('loadPost') loadPost;
    @Action('editPost') editPost;
    @Getter('getCurrentPost') currentPost : Post;
    @Getter('getCurrentUser') currentUser : User;

    editor: Editor = null;

    created() {
        this.loadPost(this.$route.params.postId);
    }

    // @todo: refactor to a component
    async addImageBlobHook(blob: Blob, callback: any) {
        if (blob.size > 1_048_576) {
            alert('Het bestand mag niet groter zijn dan 1MB');
            return;
        }
        let result: string = await this.uploadImage(blob);
        callback(tbntConfig.API_BASE_URL + 'file/' + result, '');
    }

    async save() : Promise<void> {
        let markdown = (await this.getEditor()).invoke('getMarkdown');
        if (markdown.length == 0) return;
        this.currentPost.content = this.replaceAll(markdown, '<br>', '\n');
        this.currentPost.url = this.replaceAll(this.currentPost.title.toLowerCase(), ' ', '-');
        
        // @todo: Typescript u do dis
        let postbase: PostBase = new PostBase();
        postbase.id = this.currentPost.id;
        postbase.title = this.currentPost.title;
        postbase.content = this.currentPost.content;
        postbase.url = this.currentPost.url;

        let data = await this.editPost(postbase);
        this.$router.push('/post/' + data.id + '/' + data.url);
    }
    
    // @todo: to a library
    replaceAll(str: string, find: string, replace: string) : string {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    async getEditor() : Promise<Editor> {
        while (this.$refs.toastUiEditor == undefined) await sleep(100);
        
        if (this.editor === null) {
            this.editor = (this.$refs.toastUiEditor as Editor);
            this.editor.invoke('addHook', 'addImageBlobHook', this.addImageBlobHook);
        }
        return this.editor;
    }

    @Watch('currentPost')
    async onCurrentPostChanges() {
        if (this.currentPost === null) return;
        (await this.getEditor()).invoke('setMarkdown', this.currentPost.content);
    }
}
