










































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';
import { Remarkable } from 'remarkable';
import extLink from 'remarkable-extlink';
import moment from 'moment';
import { Editor } from '@toast-ui/vue-editor';
import Volatimer from '../../components/volatimer'
import '../../style/editor.scss';
import { Reply } from '../../store/post/types';
import * as config from '../../config';
import image from '../../../assets/tbnt.png';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

@Component({
    components: {
        Volatimer,
        Editor
    },
    filters: {
        postDate: (input: Date) : string => moment(input).format('D-M-yyyy HH:mm')
    }
})
export default class Post extends Vue {
    @Action('loadPost') loadPost;
    @Getter('getCurrentPost') currentPost;
    @Action('loadLoggedIn') loadLoggedIn;
    @Getter('getLoggedIn') loggedIn;
    @Getter('getCurrentUser') currentUser;
    @Action('upvote') upvote;
    @Getter('getNewReply') newReply: Reply;
    @Mutation('setNewReply') setNewReply;
    @Action('publishReply') publishReply;
    @Getter('getCurrentReplies') currentReplies;
    @Action('upvoteReply') upvoteReply;
    @Action('uploadImage') uploadImage;
    @Action('clearCurrent') clearCurrent;

    postContent: string = null;
    baseUrl: string;

    editor: Editor = null;

    created() {
        this.clearCurrent();
        this.baseUrl = config.API_BASE_URL;
        this.loadPost(this.$route.params.postId);
    }

    async getEditor() : Promise<Editor> {
        while (this.$refs.toastUiEditor == undefined) await sleep(100);

        if (this.editor === null) {
            this.editor = (this.$refs.toastUiEditor as Editor);
            this.editor.invoke('addHook', 'addImageBlobHook', this.addImageBlobHook);
        }
        return this.editor;
    }

    async mounted() {
        await this.getEditor();
    }

    async addImageBlobHook(blob: Blob, callback: any) {
        if (blob.size > 1_048_576) {
            alert('Het bestand mag niet groter zijn dan 1MB');
            return;
        }
        let result: string = await this.uploadImage(blob);
        callback(config.API_BASE_URL + 'file/' + result, '');
    }

    @Watch('currentPost')
    onCurrentPostChanges() : void {
        if (this.currentPost === null) return;

        document.title = this.currentPost.title + ' - Technobabble Nerd Talk';
        
        this.addMetaTag('twitter:card', 'summary');
        this.addMetaTag('twitter:title', document.title);
        this.addMetaTag('twitter:image', image);
        
        this.addMetaTag('og:title', document.title);
        this.addMetaTag('og:type', this.currentPost.type);
        this.addMetaTag('og:image', image);
        this.addMetaTag('og:url', config.BASE_URL + 'post/' + this.currentPost.id + '/' + this.currentPost.url);

        var md = new Remarkable();
        md.use(extLink, { host: 'technobabblenerdtalk.nl'});
        this.postContent = md.render(this.currentPost.content);
    }

    addMetaTag(property: string, content: string) {
        var meta = document.createElement('meta');
        meta.setAttribute('property', property);
        meta.content = content;
        document.getElementsByTagName('head')[0].appendChild(meta);
    }

    upvotePost(postId: string) : void {
        this.currentPost.upvotes++;
        this.currentPost.upvotedByYou = true;
        this.upvote(postId);
    }

    upvotePostReply(reply: Reply) : void {
        reply.upvotes++;
        reply.upvotedByYou = true;
        this.upvoteReply(reply.id);
    }

    // @todo: implement lookup, or in reply interface?
    checkDisabled(replyId: string) : boolean {
        return false;
    }

    async reply() : Promise<void> {
        let markdown = (this.$refs.toastUiEditor as Editor).invoke('getMarkdown');
        if (markdown.length == 0) return;
        let reply = new Reply();
        reply.content = markdown;
        this.setNewReply(reply);
        let addedReply = await this.publishReply();
        let s: string[] = [];
        this.currentReplies.push(addedReply.data);
        (this.$refs.toastUiEditor as Editor).invoke('setMarkdown', '');
    }

    renderMd(input: string) : string {
        var md = new Remarkable();
        md.use(extLink, { host: 'technobabblenerdtalk.nl'});
        return md.render(input);
    }

    quote(quoted: Reply) {
        let current = (this.$refs.toastUiEditor as Editor).invoke('getMarkdown');
        let newText: string = '>**' + quoted.username + ' zei op ' + moment(quoted.published).format('D-M-yyyy HH:mm') + ':**' + '\n>\n';
        let lines = quoted.content.split('\n');
        for (let index = 0; index < lines.length; index++) {
            const line = lines[index];
            newText += '>' + line + '\n';
        }
        newText += current;
        (this.$refs.toastUiEditor as Editor).invoke('setMarkdown', newText);
    }

    getIcon(postType: string) {
        switch (postType) {
            case 'News':
                return 'quote-right';
            case 'Review':
                return 'wrench';
            case 'Question':
                return 'question';
            case 'FreeForm':
                return 'comment';
            case 'HowTo':
                return 'lightbulb';
            case 'BlogPost':
                return 'pencil-alt';
            default:
                break;
        }
    }

    replyEditor(reply: Reply) : Editor {
        let editor = Vue.extend();
        return new editor({propsData: { 
            height: '250px',
            initialEditType: 'wysiwyg',
            previewStyle: 'tab'
        }});
    }
}
