import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import {
    faAsterisk,
    faAward,
    faCrown,
    faEuroSign,
    faComment,
    faCommentAlt,
    faExclamationCircle,
    faQuestion,
    faWrench,
    faQuoteRight,
    faUser,
    faCheck,
    faCheckCircle,
    faExclamationTriangle,
    faEnvelope,
    faKey,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faFile,
    faTrophy,
    faCheckDouble,
    faThumbtack,
    faLightbulb,
    faPencilAlt,
    faDoorOpen
} from '@fortawesome/free-solid-svg-icons';

// Add font awesome component to Vue
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Add used icons to the library
library.add(
    faAsterisk,
    faAward,
    faCrown,
    faEuroSign,
    faComment,
    faCommentAlt,
    faExclamationCircle,
    faQuestion,
    faWrench,
    faQuoteRight,
    faUser,
    faCheck,
    faCheckCircle,
    faExclamationTriangle,
    faEnvelope,
    faKey,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faFile,
    faTrophy,
    faCheckDouble,
    faThumbtack,
    faLightbulb,
    faPencilAlt,
    faDoorOpen
);
