import { ActionContext, Module, GetterTree, ActionTree, MutationTree, Action } from 'vuex';
import { Leader, LeaderBoardState } from './types';
import { RootState } from '../root-state';
import { ServiceBase } from '../../services/service-base';
import { AxiosResponse } from 'axios';

export const getters: GetterTree<LeaderBoardState, RootState> = {
    getLeaders(state: LeaderBoardState) {
        return state.leaders;
    } 
};

export const actions: ActionTree<LeaderBoardState, RootState> = {
    loadLeaders(context: ActionContext<LeaderBoardState, RootState>) : void {
        let serviceBase = new ServiceBase();
        serviceBase.axios.get('/leader/xp').then(i => {
            context.commit('setLeaders', i.data);
        });
    },
};

export const mutations: MutationTree<LeaderBoardState> = {
    setLeaders(state: LeaderBoardState, leaders: Leader[]) {
        state.leaders = leaders;
    }
};

export const state: LeaderBoardState = {
    leaders: null
};

const namespaced: boolean = false;
export const leaderBoard: Module<LeaderBoardState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};