


























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { user } from '../store/user';
import { UserRegistration } from '../store/user/types';
import Password from 'vue-password-strength-meter';
import { ServiceBase } from '../services/service-base';

@Component({
    components: {
        Password
    }
})
export default class Signup extends Vue {
    @Action('startNewUserRegistration') startNewUserRegistration;
    @Action('registerUser') registerUser;
    @Getter('getUserRegistration') userRegistration : UserRegistration;

    validInviteClass: string = null;
    validInviteFeedback: string = null;
    validInvite: boolean = false;
    validEmailClass: string = null;
    validEmailFeedback: string = null;
    validEmail: boolean = false;
    validUsernameClass: string = null;
    validUsernameFeedback: string = null;
    validUsername: boolean = false;
    validPasswordClass: string = null;
    validPassword: boolean = false;
    registrationComplete: boolean = false;
    passwordScore: number = 0;
    serviceBase: ServiceBase;

    created() : void {
        this.startNewUserRegistration(this.$route.query.invite_code);
        this.serviceBase = new ServiceBase();
    }

    async checkAndRegister(e: Event) : Promise<void> {
        e.preventDefault();
        if (this.canRegister()) {
            let succeeded = await this.registerUser(this.userRegistration);
            if (succeeded) this.registrationComplete = true;
        }
    }

    async isValidInviteCode(inviteCode: string) : Promise<boolean> {
        if (inviteCode == null || inviteCode == '') return false;
        var result = false;
        let j = await this.serviceBase.axios.get('/auth/invite-code/' + inviteCode);
        if (j.status == 200) {
            if (j.data.available) {
                result = true;
            } else {
                this.validInviteFeedback = 'Dit is geen geldige invite code';
            }
        }
        if (result) {
            this.validInvite = true;
            this.validInviteFeedback = '';
            this.validInviteClass = 'is-success';
        } else {
            this.validInviteClass = 'is-danger';
        }
        return result;
    }

    async isValidEmail(emailAddress: string) : Promise<boolean> {
        if (emailAddress == null) return false;

        var regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var result = emailAddress !== undefined && regEx.test(emailAddress);

        if (result) {
            result = false;
            let j = await this.serviceBase.axios.get('/auth/email-addresses/' + emailAddress);
            if (j.status == 200) {
                if (j.data.available) {
                    result = true;
                } else {
                    this.validEmailFeedback = 'E-mailadres al in gebruik';
                }
            }
        } else {
            this.validEmailFeedback = 'E-mailadres onjuist';
        }

        if (result) {
            this.validEmail = true;
            this.validEmailFeedback = '';
            this.validEmailClass = 'is-success';
        } else {
            this.validEmailClass = 'is-danger';
        }

        return result;
    }

    async isValidUsername(username: string) : Promise<boolean> {
        if (username == null) return false;
        
        var invalidUsernames: string[] = ['admin', 'pussydestroyer99'];
        var regEx = /^[A-Za-z0-9_-]{3,16}$/;
        var result = username !== undefined && invalidUsernames.find(i => i == username) == undefined && regEx.test(username);

        if (result) {
            result = false;
            let j = await this.serviceBase.axios.get('/auth/users/' + username);
            if (j.status == 200) {
                if (j.data.available) {
                    result = true;
                } else {
                    this.validUsernameFeedback = 'Gebruikersnaam al in gebruik';
                }
            }
        } else {
            this.validUsernameFeedback = 'Gebruikersnaam niet toegestaan';
        }

        if (result) {
            this.validUsername = true;
            this.validUsernameFeedback = '';
            this.validUsernameClass = 'is-success';
        } else {
            this.validUsernameClass = 'is-danger';
        }

        if (username === 'pussydestroyer99') {
            alert('haha, echt grappig, +100 comedy!1');
        }

        return result;
    }

    isValidPassword(password: string) : boolean {
        var result = this.passwordScore >= 3;

        if (result) {
            this.validPassword = true;
            this.validPasswordClass = 'is-success';
        } else {
            this.validPasswordClass = 'is-danger';
        }

        return result;
    }

    saveScore(score: number) : void {
        this.passwordScore = score;
    }

    canRegister() : boolean {
        return this.validInvite && this.validEmail && this.validUsername && this.validPassword;
    }

    @Watch('userRegistration.inviteCode') 
    InviteCodeChanged() {
        this.isValidInviteCode(this.userRegistration.inviteCode);
    }
}
