












































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';
import Header from './components/header'
import Chat from './components/chat';
import { Post } from './store/post/types';

@Component({ components: { Header, Chat } })
export default class App extends Vue {
    @Getter('getUserSettings') userSettings;
    @Action('loadUserSettings') loadUserSettings;
    @Mutation('setCurrentRoom') setCurrentRoom;
    @Getter('getCurrentPost') currentPost: Post;

    created() : void {
        this.loadUserSettings();

        document.title = "Technobabble Nerd Talk - de plek voor technologie!";
    }

    mounted() : void {
        document.addEventListener('keydown', this.onCenterStage.bind(this));
    }

    onCenterStage(event: KeyboardEvent) : void {
        if (event.key == ' ' && event.ctrlKey) {
            (document.getElementsByClassName("chat-input")[0] as HTMLElement).focus();
        }

        if (event.key == ',' && event.ctrlKey) {
            
        }
    }

    @Watch('$route.path')
    onRouteChanges() : void {
        this.setCurrentRoom(this.$route.path);
    }
}
