import { Component, Vue } from 'vue-property-decorator';

export default class Title extends Vue {    
    created() {
        const { title } = this.$options;
        if (title) {
            const theTitle = typeof title === 'function' ? title.call(this) : title
            if (theTitle) {
                document.title = theTitle
            }
        }
    }
}